import { MessageService } from '../../../../services/message.service';
import { HinweisModalData } from '../../../../ui-components/modalhinweisdialog/hinweismodalData';
import { Messages } from '../../../../ui-components/model/Messages';

/**
 * handles info dialog functionality for facettenfilters that need it
 */
export class InfoPopupDelegete {
  private info = new Map<string, HinweisModalData>();

  /**
   * C-tor
   * @param {MessageService} messageService
   * @param {Map<string, HinweisModalData>} info with keys being ids of option that has an info popup, values
   * are modal data to show
   */
  constructor(public messageService: MessageService) {
    //  Eignungstest
    this.info.set(
      'Eignungstest-2',
      new HinweisModalData(Messages.POPUP_UEBERSCHRIFT_OSA, '', Messages.POPUP_INFO_OSA)
    );
    this.info.set(
      'Eignungstest-1',
      new HinweisModalData(
        Messages.POPUP_UEBERSCHRIFT_STUDIENCHECK,
        '',
        Messages.POPUP_INFO_STUDIENCHECK
      )
    );

    //  Duales Studienmodell
    this.info.set(
      'Duales Studienmodell-1',
      new HinweisModalData(
        Messages.POPUP_UEBERSCHRIFT_AUSBILDUNGSINTEGRIERENDE_STUDIENGAENGE,
        '',
        Messages.POPUP_INFO_AUSBILDUNGSINTEGRIERENDE_STUDIENGAENGE
      )
    );
    this.info.set(
      'Duales Studienmodell-2',
      new HinweisModalData(
        Messages.POPUP_UEBERSCHRIFT_BERUFSINTEGRIERENDE_STUDIENGAENGE,
        '',
        Messages.POPUP_INFO_BERUFSINTEGRIERENDE_STUDIENGAENGE
      )
    );
    this.info.set(
      'Duales Studienmodell-3',
      new HinweisModalData(
        Messages.POPUP_UEBERSCHRIFT_BERUFSBEGLEITENDE_STUDIENGAENGE,
        '',
        Messages.POPUP_INFO_BERUFSBEGLEITENDE_STUDIENGAENGE
      )
    );
    this.info.set(
      'Duales Studienmodell-4',
      new HinweisModalData(
        Messages.POPUP_UEBERSCHRIFT_PRAXISINTEGRIERENDE_STUDIENGAENGE,
        '',
        Messages.POPUP_INFO_PRAXISINTEGRIERENDE_STUDIENGAENGE
      )
    );
    this.info.set(
      'Duales Studienmodell-6',
      new HinweisModalData(
        Messages.POPUP_UEBERSCHRIFT_AUSBILDUNGSBEGLEITENDE_STUDIENGAENGE,
        '',
        Messages.POPUP_INFO_AUSBILDUNGSBEGLEITENDE_STUDIENGAENGE
      )
    );
  }

  /**
   * do we have info for option key?
   * @param {string} key
   * @returns {boolean}
   */
  public hasInfoFor(key: string): boolean {
    return this.info.has(key);
  }

  /**
   *
   * Service-Call um Info-Dialog anzuzeigen
   */
  public showInfoFor(key: string): void {
    if (this.hasInfoFor(key)) {
      this.messageService.showHinweisDialog.next(this.info.get(key));
    }
  }

  public getInfo(): Map<string, HinweisModalData> {
    return this.info;
  }
}
