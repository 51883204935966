import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggingService } from '@infosysbub/ng-lib-dpl3';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StudisuConfig, getStudisuConfig } from '../config/studisu.config';
import { SHOW_MODAL_ON_ERROR } from '../error/interceptor.token';
import { StudisuError } from '../error/studisu-error';
import { Studienangebot } from '../main/suche/services/model/Studienangebot';
import { UrlParamService } from '../main/suche/services/url-param.service';
import { FacettenResult } from '../model/FacettenResult';
import { SearchResult } from '../model/SearchResult';

/**
 * Serviceklasse zur Ermittlung von Studienangeboten ueber Http-Backendzugriff
 *
 */
@Injectable()
export class StudienangeboteService {
  public static readonly ERROR_PARAMETERS_INVALID = 'Parameters not valid';
  private readonly serverURLangebote: string;
  private readonly serverURLfacetten: string;
  private readonly serverURLids: string;
  private conf: StudisuConfig = getStudisuConfig();

  /**
   * Konstruktor, der Objekte zum Zugriff auf das Backend erwartet
   * @param http Instanz zum Ausfuehren von Http-Requests
   * @param urlParamService Serviceinstanz zum Auslesen der URL-Parameter
   * @param logger Serviceinstanz fuer Logging
   * @param errorHandlerService Serviceinstanz fuer Fehlerauswertung
   */
  constructor(
    private http: HttpClient,
    private urlParamService: UrlParamService,
    private logger: LoggingService
  ) {
    this.serverURLangebote =
      this.conf.studienangeboteServiceHost + this.conf.studienangeboteServicePath;
    this.serverURLfacetten = this.conf.studienangeboteServiceHost + this.conf.facettenServicePath;
    this.serverURLids =
      this.conf.studienangeboteServiceHost + this.conf.studienangeboteIdsServicePath;
  }

  /**
   * ermittelt ueber einen Backendzugriff Studienangebote ensprechend den in der URL enthaltenen Sucheinschraenkungen
   */
  public getStudienangebote(): Observable<SearchResult> {
    return this.findStudienangebote(this.serverURLangebote);
  }

  /**
   * ermittelt ueber einen Backendzugriff Studienangebot-IDs ensprechend den in der URL enthaltenen Sucheinschraenkungen
   */
  public getStudienangeboteIds(): Observable<SearchResult> {
    return this.findStudienangebote(this.serverURLids);
  }

  /**
   * ermittelt ueber einen Backendzugriff Studienangebote ensprechend den in der URL enthaltenen Sucheinschraenkungen
   */
  private findStudienangebote(serverURL: string): Observable<SearchResult> {
    const params: HttpParams = this.urlParamService.getUrlParamsForBackend();

    if (!this.validateParams(params)) {
      return throwError(() => StudienangeboteService.ERROR_PARAMETERS_INVALID);
    }

    this.logger.info(`requestet Studienangebote on: ${serverURL}?${params.toString()}`);
    return this.http
      .get(serverURL, { params, context: new HttpContext().set(SHOW_MODAL_ON_ERROR, true) })
      .pipe(
        map((data) => this.extractDataToSearchResult(data)),
        catchError((error) => {
          throw new StudisuError('Fehler beim Laden der Studienangebote.', error);
        })
      );
  }

  /**
   * ermittelt ueber einen Backendzugriff Studienangebote ensprechend den in der URL enthaltenen Sucheinschraenkungen
   */
  public getFacettenWerte(): Observable<FacettenResult> {
    const params: HttpParams = this.urlParamService.getUrlParamsForBackend();

    if (!this.validateParams(params)) {
      return throwError(() => StudienangeboteService.ERROR_PARAMETERS_INVALID);
    }

    this.logger.info(`requestet Facettenwerte on: ${this.serverURLfacetten}?${params.toString()}`);
    return this.http
      .get(this.serverURLfacetten, {
        params,
        context: new HttpContext().set(SHOW_MODAL_ON_ERROR, true)
      })
      .pipe(
        map((data) => {
          let aggregationResult: FacettenResult = <FacettenResult>data;
          return aggregationResult;
        }),
        catchError((error) => {
          throw new StudisuError('Fehler beim Laden der Facetten.', error);
        })
      );
  }

  /**
   * uebernimmt die vom Backend erhaltenen Suchergebnisse in das auf Clientseite verwendete Suchergebnisobjekt
   * @param data json-Antwortobjekt vom Backend
   */
  private extractDataToSearchResult(data: Object) {
    let searchResult: SearchResult = <SearchResult>data;
    // weil der typecast die functionen der klasse studienangebot nicht anfuegt
    // und der constructor echte logik enthaelt konstruieren wir die items nachtraeglich
    searchResult.items = searchResult.items.map((x) => new Studienangebot(x));
    searchResult.hasSearched = true;
    return searchResult;
  }

  /**
   * This function will validate the query parameters. Unused or unknown url parameters
   * will be ignored by this check.
   * @param queryParams false when parameters are missing or its values malformed
   */
  private validateParams(queryParams: HttpParams) {
    return (
      queryParams.has(UrlParamService.PARAM_STUDIENFELDER) ||
      queryParams.has(UrlParamService.PARAM_STUDIENFAECHER) ||
      this.urlParamService.isDualesStudiumSet(queryParams) ||
      queryParams.has(UrlParamService.PARAM_SUCHWORTE)
    );
  }
}
